<template>
  <main class="success">
    <img
      src="@/assets/images/svg/payment-success.svg"
      class="image"
    >
    <h2>{{ $t('pages.paymentSuccess.title') }}</h2>
    <p>{{ $t('pages.paymentSuccess.content') }}</p>
    <btn
      w="100%"
      @click="$router.push('/')"
    >
      {{ $t('pages.paymentSuccess.ok') }}
    </btn>
  </main>
</template>

<script lang="ts">
import { onBeforeUnmount, defineComponent, onMounted } from 'vue';
import { RouteNames } from 'router/names';

export default defineComponent({
  name: RouteNames.PaymentSuccess,
  setup() {
    onMounted(() => {
      document.body.classList.add('auth-layout--body');
    });
    onBeforeUnmount(() => {
      document.body.classList.remove('auth-layout--body');
    });
  },
});
</script>

<style lang="scss" src="./success.scss"></style>
