<template>
  <main class="failed">
    <img
      src="@/assets/images/svg/payment-failed.svg"
      class="image"
    >
    <h2>{{ $t('pages.paymentFailed.title') }}</h2>
    <i18n-t
      keypath="pages.paymentFailed.content"
      tag="p"
    >
      <template #support>
        <router-link to="/technical-support">
          {{ $t('pages.paymentFailed.technicalSupport') }}
        </router-link>
      </template>
    </i18n-t>
    <btn
      w="100%"
      @click="$router.push({ name: 'profile-wallet' })"
    >
      {{ $t('pages.paymentFailed.retry') }}
    </btn>
  </main>
</template>

<script lang="ts">
import { onBeforeUnmount, defineComponent, onMounted } from 'vue';
import { RouteNames } from 'router/names';

export default defineComponent({
  name: RouteNames.PaymentFailed,
  setup() {
    onMounted(() => {
      document.body.classList.add('auth-layout--body');
    });
    onBeforeUnmount(() => {
      document.body.classList.remove('auth-layout--body');
    });
  },
});
</script>

<style lang="scss" src="./failed.scss"></style>
