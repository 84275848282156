
import { onBeforeUnmount, defineComponent, onMounted } from 'vue';
import { RouteNames } from 'router/names';

export default defineComponent({
  name: RouteNames.PaymentSuccess,
  setup() {
    onMounted(() => {
      document.body.classList.add('auth-layout--body');
    });
    onBeforeUnmount(() => {
      document.body.classList.remove('auth-layout--body');
    });
  },
});
